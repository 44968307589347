* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --cor-principal: #293583;
}

/*HEADER*/
.logo {
  width: 100%;
  height: 10vh;
  background-color: var(--cor-principal);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 150px;
  cursor: pointer;
  margin-right: 2rem;
}

.btnvoltar,
.logo a {
  color: #fff;
  margin-left: 2rem;
}

.btnvoltar
{
  width: 100%;
  text-align: center;
}

/*PAGE*/
.page {
  width: 100%;
  height: 90vh;
  background-color: #fff;
}

/*DIVIDER*/
.container-divider {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* Para layout em tela maior */
}

/* Esquerda e direita divididas igualmente */
.container-left,
.container-right {
  width: 50%;
  height: 90vh;
}

.bg-imagem {
  background-image: url('./assets/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.box-opcao {
  width: 50%;
  min-height: 500px;
  height: auto;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--cor-principal);
  color: var(--cor-principal);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 7px 8px 9px 7px rgba(0, 0, 0, 0.7);
}

.box-opcao h2 {
  color: #fff;
  text-align: center;
}

.container-opcao {
  width: 90%;
  height: 80px;
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 22px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-alerta {
  background: #fff;
  width: 90%;
  font-size: 25px;
  padding: 5rem;
  border-radius: 20px;
}

.container-form {
  background: #fff;
  width: 90%;
  height: 350px;
  font-size: 25px;
  padding: 10px;
  border-radius: 20px;
}

.container-opcao:hover {
  opacity: 0.3;
}

.box-opcao h5 {
  transition: 0.5s;
  cursor: pointer;
}

.box-opcao h5:hover {
  opacity: .4;
  text-decoration: underline;
}

.container-form button {
  width: 80%;
  background-color: var(--cor-principal);
  color: #fff;
  font-weight: bold;
  border-radius: 20px;
  padding: 10px;
  border: none;
  margin-top: 20px;
}

.dots-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dots-loader div {
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out both;
}

.dots-loader div:nth-child(1) {
  animation-delay: -0.32s;
}

.dots-loader div:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}


/* RESPONSIVE DESIGN */

/* Para telas menores que 1024px (tablets e pequenos laptops) */
@media (max-width: 1024px) {

  .container-left,
  .container-right {
    width: 100%;
    /* Empilhar colunas verticalmente */
    height: auto;
  }

  .box-opcao {
    width: 80%;
    /* Ajusta a largura da caixa */
    min-height: 300px;
  }

  .align-center {
    width: 90%;
    /* Ajusta para telas menores */
  }
}

@media (max-width: 800px) {
  .box-opcao {
    min-height: 500px;
    overflow: auto;
    width: 95%;
    height: 90vh;
  }

  .container-alerta {
    min-height: 500px;
    overflow: auto;
  }

  .container-form {
    height: 450px;
  }
}